import { getLabel } from '@/utils/labels.js';

export default (authGuard, guestGuard, publicGuard) => [
    ...guestGuard([
        {
            path: '/login',
            name: 'LoginParent',
            redirect: { name: 'Login' },
            component: () => import('@/pages/login'),
            children: [
                {
                    path: '',
                    name: 'Login',
                    meta: {
                        name: 'Login',
                    },
                    component: () => import('@/pages/login/LoginForm.vue'),
                },
                // {
                //     path: 'request-new-password',
                //     name: 'requestNewPass',
                //     meta: { name: 'Contraseña olvidada' },
                //     component: () => import('@/pages/login/RequestNewPass.vue'),
                // },
                // {
                //     path: 'new-password',
                //     name: 'newPassword',
                //     meta: { name: 'Nueva contraseña' },
                //     component: () => import('@/pages/login/NewPassword.vue'),
                // },
            ],
        },
    ]),

    ...publicGuard([
        {
            name: '404',
            path: '/:pathMatch(.*)*',
            redirect: { name: 'Home' },
            // component: () => import('/src/pages/home/FourCeroFour.vue'),
        },

        // {
        //     path: '/',
        //     name: 'Home',
        //     // redirect: { name: 'DocsHome' },
        //     component: () => import('@/pages/home'),
        // },
        // {
        //     path: '/ventas',
        //     name: 'Ventas',
        //     // redirect: { name: 'DocsHome' },
        //     component: () => import('@/pages/ventas'),
        // },
    ]),
    ...authGuard([
        {
            path: '/',
            name: 'Home',
            // redirect: () => getRedirect() ,
            component: () => import('@/pages/home'),
            meta: {
                pageTitle: getLabel('Home'),
                pageTitleMobile: getLabel('Home'),
                dashboard: 'home',
                menu: true,
            },
        },
        {
            path: '/ventas',
            name: 'Ventas',
            meta: {
                permissions: 'Dashboard Sales',
                pageTitle: getLabel('sales'),
                pageTitleMobile: getLabel('sales'),
                dashboard: 'sales',
                menu: true,
            },
            // redirect: { name: 'DocsHome' },
            component: () => import('@/pages/ventas'),
        },

        {
            path: '/financiero',
            name: 'Financiero',
            meta: {
                permissions: 'Dashboard Finance',
                pageTitle: 'Finance',
                dashboard: 'finance',
                menu: true,
            },
            // redirect: { name: 'DocsHome' },
            component: () => import('@/pages/financiero'),
        },

        {
            path: '/talento',
            name: 'Talento',
            meta: {
                permissions: 'Dashboard Talent',
                pageTitle: getLabel('talent'),
                pageTitleMobile: getLabel('talent'),
                dashboard: 'talent',
                menu: true,
            },
            // redirect: { name: 'DocsHome' },
            component: () => import('@/pages/talento'),
        },

        {
            path: '/talento-detalle',
            name: 'TalentoDetalle',
            meta: {
                permissions: 'Dashboard Talent',
                pageTitle: getLabel('hires_detail'),
                pageTitleMobile: getLabel('hires_detail'),
                dashboard: 'hires_detail',
            },
            // redirect: { name: 'DocsHome' },
            component: () => import('@/pages/talentodetalle'),
        },

        {
            path: '/mktycom',
            name: 'Mktycom',
            meta: {
                permissions: 'Dashboard Marketing',
                pageTitle: getLabel('comm_mkt_and_esg'),
                pageTitleMobile: getLabel('cme'),
                dashboard: 'marketing',
                menu: true,
            },
            // redirect: { name: 'DocsHome' },
            component: () => import('@/pages/marketing'),
        },

        {
            path: '/clientes-top',
            name: 'clientes-top',
            meta: {
                permissions: 'Dashboard Client',
                pageTitle: getLabel('key_clients'),
                pageTitleMobile: getLabel('key_clients'),
                dashboard: 'key_clients',
                menu: true,
            },

            // redirect: { name: 'DocsHome' },
            component: () => import('@/pages/clientestop'),
        },

        {
            path: '/not-allowed',
            name: 'NotAllowed',
            meta: {
                pageTitle: getLabel('Coming Soon'),
            },

            // redirect: { name: 'DocsHome' },
            component: () => import('@/pages/notAllowed/notAllowed.vue'),
        },
        {
            path: '/coming-soon',
            name: 'mobileComingSoon',
            meta: {
                permissions: 'Dashboard Client',
                pageTitle: getLabel('Coming Soon'),
            },

            // redirect: { name: 'DocsHome' },
            component: () => import('@/pages/comingSoon'),
        },
    ]),
];

// import { state } from '@/store'

// function getRedirect(){
//     if(state.device == 'desktop'){
//         return { name: 'Ventas' }
//     }
//     else {
//         return { name: 'Ventas' }
//     }

// }
