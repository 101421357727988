<!--suppress CssUnusedSymbol -->
<template>
    <div
        :class="[
            size,
            color,
            {
                'font-bold': bold,
                capitalize: capitalize,
            },
        ]"
        class="module-labels flex"
    >
        <slot />
        <div v-if="suffix !== ''" class="flex">
            {{ suffix }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ValueContainer',
        props: {
            size: {
                type: String,
                required: false,
                default: () => 'md',
            },
            color: {
                type: String,
                required: false,
                default: () => 'text-dark-blue',
            },
            bold: {
                type: Boolean,
                required: false,
                default: () => true,
            },
            capitalize: {
                type: Boolean,
                required: false,
                default: () => true,
            },
            suffix: {
                type: String,
                required: false,
                default: () => '',
            },
        },
    };
</script>

<style scoped>
    .xxxs {
        font-size: 0.5rem;
        line-height: 0.6rem;
    }
    .xxs {
        font-size: 0.6rem;
        line-height: 0.6rem;
    }
    .xs {
        font-size: 0.8rem;
        line-height: 0.8rem;
    }
    .sm {
        font-size: 1rem;
        line-height: 1rem;
    }
    .md {
        font-size: 1.2rem;
        line-height: 1.2rem;
    }
    .lg {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }
    .xl {
        font-size: 1.6rem;
        line-height: 1.6rem;
    }
    .xxl {
        font-size: 1.8rem;
        line-height: 1.8rem;
    }
    .xxxl {
        font-size: 2rem;
        line-height: 2rem;
    }
    .module-labels {
        font-family: Montserrat, sans-serif !important;
    }
</style>
