import { actions } from './store';
actions.setMode();
import { createApp } from 'vue';
import App from './App.vue';
const app = createApp(App);

import { isDesktop } from '@/composables/utils.js';

import '@/assets/css/main.css';

//INSTALL CONFIGURED HIGHCHARTS
import { HighchartsVue, Highcharts } from './mainPlugins/highcharts.js';
app.use(HighchartsVue, { highcharts: Highcharts });

//INSTALL ROUTER
import router from './router';
app.use(router);

import { getLabel } from '@/utils/labels.js';
app.use(getLabel);

import { faConfig, FontAwesomeIcon } from './mainPlugins/fontawesome';

faConfig();
app.component('font-awesome-icon', FontAwesomeIcon);

import VueTippy from 'vue-tippy';
import 'tippy.js/dist/tippy.css'; // optional for styling

app.use(VueTippy, {
    directive: 'tippy', // => v-tippy
    component: 'tippy', // => <tippy/>

    defaultProps: {
        placement: 'top',
        allowHTML: true,
        arrow: true,
        touch: !isDesktop,
        trigger: isDesktop ? 'mouseenter focus' : 'mouseenter click',
    },
});

app.mount('#app');
